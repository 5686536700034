(function () {
    angular.module('informaApp')
        .component('filePreview', {
            templateUrl: 'components/admin-sections/whats-new-section/new-feature-form/file-preview/template.html',
            controller: FilePreview,
            bindings: {
                onFileChosen: '<',
                model: '<'
            }
        });

    function FilePreview(NewFeatureFileTypes, Upload) {
        this.onPlayerReady = (api) => {
            this.videoPlayerApi = api;
        };

        this.fileTypeList = {
            video: {id: NewFeatureFileTypes.video, pattern: /^video\/.+$/},
            image: {id: NewFeatureFileTypes.image, pattern: /^image\/.+$/}
        };

        this.$onInit = () => {
            if (this.model) {
                this.fileType = this.model.fileType;
                this.file = this.model.file ? {$ngfBlobUrl: this.model.file } : null;
                this.previewVideoFile = this.model.videoPreview ? {$ngfBlobUrl: this.model.videoPreview} : null;
            }
        };

        this.onFileSelected = (file) => {
            this.previewVideoFile = null;

            if (file) {
                Upload.dataUrl(file).finally(() => {
                    this.fileType = getFileType(file, this.fileTypeList);

                    emitOnFileChosen(this);
                });
            }
        };

        this.onPreviewSelected = (file) => {
            if (file) {
                Upload.dataUrl(file).finally(() => this.videoPlayerApi && this.videoPlayerApi.stop());
                emitOnFileChosen(this);
            }
        };

        this.clear = () => {
            this.fileType = null;
            this.file = null;
            this.previewVideoFile = null;

            emitOnFileChosen(this);
        };

        this.clearPreview = () => {
            this.previewVideoFile = null;

            emitOnFileChosen(this);
        };
    }

    function emitOnFileChosen(context) {
        if (context.onFileChosen) {
            context.onFileChosen(context.fileType, context.file, context.previewVideoFile);
        }
    }

    function getFileType(file, fileTypeList) {
        const fileType = Object.values(fileTypeList).find(x => x.pattern.test(file.type));
        return fileType ? fileType.id : null;
    }
})();